import request from '@/utils/request'

// 查看比赛列表分页
export function getSelectCompetition(data) {
  return request({
    url: '/competition-management/selectCompetition',
    method: 'post',
    data
  });
}

// 根据比赛id查看有哪些赛程
export function getSelectscheduleList(params) {
  return request({
    url: '/competition-schedule/selectscheduleList',
    method: 'get',
    params
  });
}

// 根据赛程id查询实时赛况信息
export function getSelectShootingByscheduleId(params) {
  return request({
    url: '/competition-schedule/selectShootingByscheduleId',
    method: 'get',
    // headers: {
    //   token: sessionStorage.getItem('token')
    // },
    params
  });
}

// 查询靶位及实时分数
export function getSelectShootingByGroupName(params) {
  return request({
    url: '/shooting-score/selectShootingByGroupName',
    method: 'get',
    params
  });
}

// 根据选手id查询分数详情
export function getSelectShootingScore(params) {
  return request({
    url: '/shooting-score/selectShootingScore',
    method: 'get',
    params
  });
}

// 选择选手id晋级
export function playerPromotion(params) {
  return request({
    url: '/shooting-score/playerPromotion',
    method: 'get',
    params
  });
}

// 一键更改小组状态
export function updateAllGroupStatus(params) {
  return request({
    url: '/shooting-score/updateAllGroupStatus',
    method: 'get',
    params
  });
}

// 根据id删除射击成绩
export function deleteShootScore(params) {
  return request({
    url: '/shooting-score/deleteShootScore',
    method: 'get',
    params
  });
}

// 删除比赛

export function delcompetition(params) {
  return request({
    url: '/competition-management/deleteCompetition',
    method: 'GET',
    params
  })
}

// 更改靶位，设置默认成绩
export function updateDefaultScore(data) {
  return request({
    url: '/shooting-score/shooting/default/score',
    method: 'post',
    data
  });
}

// 重新计算得分

export function updateShootingScore(data) {
  return request({
    url: '/shooting-score/anew/ranking',
    method: 'post',
    data
  });
}

