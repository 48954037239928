<template>
  <!-- 决赛详情 -->
  <div class="finalDetails">
    <div class="groupBox11">
      <div
        :class="index == isactive1 ? 'active' : ''"
        @click="handelchange1(index, item)"
        v-for="(item, index) in groupList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="isDisplay">
      <div class="topBox">
        <div class="nameBox">
          <span class="span1Box">比赛名称：</span>
          <span class="span2Box">{{ groupData.comName }}</span>
        </div>
        <div class="typeBox">
          <span class="span1Box">比赛类型：</span>
          <span class="span2Box">{{ groupData.comType }}</span>
        </div>
        <div class="timeBox">
          <span class="span1Box">比赛时间：</span>
          <span class="span2Box">{{ groupData.comStartTime }}</span>
        </div>
        <div class="timeBox">
          <span class="span1Box">比赛状态：</span>
          <span class="span2Box">
            <span v-if="groupData.schuduleStatus === 1">
              <span class="ydBox ydBox1"></span>
              <span class="nameBox">未开始</span>
            </span>
            <!-- <span v-if="groupData.schuduleStatus === 2">
            <span class="ydBox ydBox4"></span>
            <span class="nameBox nameBox4">已分组</span>
          </span> -->
            <span v-if="groupData.schuduleStatus === 2">
              <span class="ydBox ydBox2"></span>
              <span class="nameBox nameBox2">进行中</span>
            </span>
            <span v-if="groupData.schuduleStatus === 3">
              <span class="ydBox ydBox3"></span>
              <span class="nameBox nameBox3">已结束</span>
            </span>
          </span>
        </div>
      </div>
      <div
        class="groupBox"
        v-if="groupData.groupNameList && groupData.groupNameList.length > 0"
      >
        <div
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index, item)"
          v-for="(item, index) in groupData.groupNameList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="groupBox groupBox1" v-else>暂无数据</div>
      <div class="midBox">
        <div class="bwxxBox">靶位信息</div>
        <div class="flBox">
          <div>
            <span class="ydBox ydBox0"></span>
            <span class="nameBox nameBox0">未开始</span>
          </div>
          <div>
            <span class="ydBox ydBox1"></span>
            <span class="nameBox nameBox1">准备</span>
          </div>
          <div>
            <span class="ydBox ydBox2"></span>
            <span class="nameBox nameBox2">试射</span>
          </div>
          <div>
            <span class="ydBox ydBox3"></span>
            <span class="nameBox nameBox3">记分赛</span>
          </div>
          <button
            v-if="updateData.status > 1 && updateData.status < 5"
            type="primary"
            size="small"
            @click="updateBtn"
          >
            <span v-if="updateData.status === 2">开始准备</span>
            <span v-if="updateData.status === 3">开始试射</span>
            <span v-if="updateData.status === 4">开始记分</span>
          </button>
          <button
            type="primary"
            style="margin-left: 20px"
            size="small"
            @click="toupingBtn"
          >
            投屏
          </button>
          <!-- <div class="timeBox">
          <span class="jsBox">倒计时：</span>
          <span class="tiBox">10:00:00</span>
        </div> -->
        </div>
        <div class="playersBox" v-if="data1 && data1.length > 0">
          <div
            class="playBox"
            :class="{ active: currentIndex === index }"
            v-for="(item, index) in data1"
            @click="editClick(item, index)"
            :key="index"
          >
            <div
              class="playerBox"
              :class="{
                LvOne: item.status === 2,
                LvTwo: item.status === 3,
                LvThree: item.status === 4,
              }"
            >
              {{ item.playerName }}
              <img
                v-if="item.playerstatus === 2"
                src="@/assets/jinji.png"
                alt=""
              />
            </div>
            <div
              class="numberBox"
              :class="{
                LvOne1: item.status === 2,
                LvTwo2: item.status === 3,
                LvThree3: item.status === 4,
              }"
            >
              {{ item.targetName }}
            </div>
          </div>
        </div>
        <div class="playersBox playersBox1" v-else>暂无数据</div>
      </div>
      <div class="botBox">
        <div class="titleBox" >
          实时排名
          <el-button style="margin-left:20px;" type="info" size="small" @click="resetScore">重新计算得分</el-button>
        </div>
        
        <div class="tableBox">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column type="index" label="排名" width="70">
            </el-table-column>
            <el-table-column label="场地" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.siteCity }} — {{ scope.row.siteName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="targetName" label="靶位" width="70">
            </el-table-column>
            <el-table-column prop="deviceName" label="设备编号" width="80">
            </el-table-column>
            <el-table-column prop="playerName" label="选手" width="80">
            </el-table-column>
            <el-table-column prop="company" label="代表队" width="100"> </el-table-column>
            <el-table-column label="已射击发数/总射击发数" width="150">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.shootCount ? scope.row.shootCount : 0 }}/{{
                    scope.row.maxShootCount
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="item in headerList"
              :key="item"
              :label="item + ''"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.map[`${item}`]">
                  {{ scope.row.map[`${item}`] }}
                </div>
                <div v-else>0</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="10" label="5" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.map['5']">{{ scope.row.map["5"] }}</div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <el-table-column prop="20" label="10" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.map['10']">{{ scope.row.map["10"] }}</div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <el-table-column prop="30" label="15" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.map['15']">{{ scope.row.map["15"] }}</div>
              <div v-else>0</div>
            </template>
          </el-table-column> -->
            <el-table-column prop="allscore" label="总环数" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.allscore">{{ scope.row.allscore }}</div>
                <div v-else>0</div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="promotion(scope.row.playerId)"
                  >晋级</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
    <ChampionshipDetails v-else ref="chamChild"></ChampionshipDetails>
    <Prepare
      ref="prepareChild"
      @getSelect="getSelect"
      :currentIndex="currentIndex"
    ></Prepare>
    <Try ref="tryChild" @getSelect="getSelect"></Try>
    <ScoringMatch
      ref="scorChild"
      @getSelect="getSelect"
      @getSelectShootingByGroupName="getSelectShootingByGroupName"
    ></ScoringMatch>
  </div>
</template>

<script>
import { getSelectShootingByscheduleId, getSelectShootingByGroupName, playerPromotion, updateAllGroupStatus, getSelectscheduleList,updateShootingScore } from '@/api/realTime/index.js'
import Prepare from './qualificationDetails/prepare.vue'
import Try from './qualificationDetails/try.vue'
import ScoringMatch from './qualificationDetails/scoringMatch.vue'
import ChampionshipDetails from './championshipDetails.vue'
import webSocket from '@/api/socket.js'
import urls from '@/utils/url.js'

export default {
  name: 'QualificationDetails',
  components: {
    Prepare,
    Try,
    ScoringMatch,
    ChampionshipDetails
  },
  props: {},
  data() {
    return {
      groupList: [
        {
          id: 1,
          name: '8人组',
          scheduleId: null
        },
        {
          id: 2,
          name: '6人组',
          scheduleId: null
        },
        {
          id: 3,
          name: '4人组',
          scheduleId: null
        },
        {
          id: 4,
          name: '总决赛',
          scheduleId: null
        }
      ],
      isactive1: 0,
      scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
      groupData: {},
      groupNameData: {
        groupName: '',
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      data1: [],
      scheduleList: [],
      tableData: [],
      currentIndex: -1, // 记录状态的变量
      isactive: 0,
      headerList: [],
      updateData: {
        groupName: '',
        scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
        status: 1
      }, // 一键开始数据
      promotionData: {
        competitionId: parseInt(sessionStorage.getItem('competitionId')),
        playerId: null,
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      }, // 晋级数据
      isDisplay: true
    }
  },
  computed: {},
  watch: {},
  created() {
    setTimeout(() => {
      this.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.groupNameData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.updateData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.promotionData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.getSelectShootingByscheduleId();
      this.getSelectscheduleList();
    }, 200)
  },
  mounted() {
    setTimeout(() => {
      webSocket.setMessageCallback(res => {
        // 在此处进行数据刷新操作即可实现数据发生改变时实时更新数据
        console.log("接收到回信fin", res);
        this.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.groupNameData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.updateData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.promotionData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.getSelectShootingByGroupName();
      })
    }, 1000)
  },
  methods: {
    // 获取赛程阶段
    async getSelectscheduleList() {
      const res = await getSelectscheduleList({ competitionId: this.promotionData.competitionId });
      console.log(res);
      if (res.status === 200) {
        this.scheduleList = res.data;
        res.data.forEach(item => {
          if (item.scheduleName === '3') {
            this.groupList[0].scheduleId = item.scheduleId;
          }
          if (item.scheduleName === '4') {
            this.groupList[1].scheduleId = item.scheduleId;
          }
          if (item.scheduleName === '5') {
            this.groupList[2].scheduleId = item.scheduleId;
          }
          if (item.scheduleName === '6') {
            this.groupList[3].scheduleId = item.scheduleId;
          }
        });
      }
    },
    // 大分组事件
    handelchange1(index, item) {
      // console.log('11');
      // console.log(index);
      // console.log(item);
      this.isDisplay = true;
      this.scheduleId = item.scheduleId;
      sessionStorage.setItem('scheduleId', item.scheduleId);
      this.groupNameData.scheduleId = item.scheduleId;
      this.updateData.scheduleId = item.scheduleId;
      this.promotionData.scheduleId = item.scheduleId;
      // console.log(this.groupNameData.groupName);
      this.isactive1 = index;
      this.isactive = 0;
      if (index === 3) {
        this.isDisplay = false;
        // sessionStorage.setItem('scheduleId', item.scheduleId);
        // console.log('111');
      }
      this.getSelectShootingByscheduleId();
    },
    // 根据赛程id查询实时赛况信息
    async getSelectShootingByscheduleId() {
      const res = await getSelectShootingByscheduleId({ scheduleId: this.scheduleId });
      // console.log(res);
      if (res.status === 200) {
        this.groupData = res.data;
        if (res.data.groupNameList.length > 0) {
          this.groupNameData.groupName = res.data.groupNameList[0];
          this.updateData.groupName = res.data.groupNameList[0];
        } else {
          this.groupNameData.groupName = '';
        }
        this.getSelectShootingByGroupName();
      }
    },
    // 查询靶位及实时分数
    async getSelectShootingByGroupName() {
      if (this.groupData.groupNameList && this.groupData.groupNameList.length > 0) {
        this.groupNameData.groupName = this.groupData.groupNameList[this.isactive];
      }
      // console.log('fin');
      const res = await getSelectShootingByGroupName(this.groupNameData);
      // console.log(res);
      this.data1 = [];
      this.tableData = [];
      if (res.status === 200) {
        if (res.data !== null) {
          this.data1 = res.data.targetList;
          this.tableData = res.data.rankingList;
          this.headerList = res.data.headerList;
          if (this.data1 && this.data1.length > 0) {
            this.updateData.status = this.data1[0].status + 1;
          }
          this.$forceUpdate();
          // if (this.isactive1 === 3) {
          //   this.$refs.chamChild.getSelectShootingByGroupName();
          // }
        }
        if (this.currentIndex !== -1) {
          this.$refs.scorChild.getSelectShootingScore();
        }
      }
    },
    // 分组事件
    handelchange(index, item) {
      // console.log('22');
      // console.log(item);
      this.groupNameData.groupName = item;
      this.updateData.groupName = item;
      // console.log(this.groupNameData.groupName);
      this.isactive = index;
      this.getSelectShootingByGroupName();
    },
    // 一键开始
    async updateBtn() {
      // console.log(this.updateData);
      this.updateData.groupNumber = parseInt(this.updateData.groupName.slice(1, 2));
      this.updateData.status = this.updateData.status > 4 ? 4 : this.updateData.status;
      const res = await updateAllGroupStatus(this.updateData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '一键开始成功！'
        });
        this.getSelectShootingByGroupName();
        const res1 = await getSelectShootingByscheduleId({ scheduleId: this.scheduleId });
        // console.log(res);
        if (res1.status === 200) {
          this.groupData = res1.data;
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 晋级事件
    async promotion(val) {
      // console.log(val);
      this.promotionData.playerId = val;
      const res = await playerPromotion(this.promotionData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '晋级成功！'
        });
        this.getSelectShootingByGroupName();
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },

    // -----------------------------------
    // 改状态
    async getSelect() {
      // console.log('111');
      this.currentIndex = -1;
    },
    // 编辑
    editClick: function (itemMethods, index) {
      this[itemMethods.status](itemMethods);
      // console.log(index);
      this.currentIndex = index;
    },
    1: function () {
      console.log('点击');
    },
    2: function (val) {
      // console.log(val);
      this.$refs.prepareChild.openEdit(val)
    },
    3: function (val) {
      this.$refs.tryChild.openEdit(val)
    },
    4: function (val) {
      this.$refs.scorChild.openEdit(val)
    },

    // ===========================
    // 投屏
    toupingBtn() {
      window.open(urls.largeScreenUrl + '/#/finals?scheduleId=' + this.updateData.scheduleId + '&token=' + sessionStorage.getItem('usertoken'),
        'finals',
        'resizable=1, scrollbars=1, fullscreen=0, height=1080, width=1920, screenX=0 , left=1920, toolbar=0, menubar=0, status=1');
    },
     // 重新计算得分
     async resetScore(){
      const params = {
        scheduleId:this.updateData.scheduleId,
        competitionId:parseInt(sessionStorage.getItem('competitionId')),
      }
      const res = await updateShootingScore(params);
      if(res.status === 200){
        this.$message({
          showClose: true,
          type: 'success',
          message: '重置成功！'
        });
        this.getSelectShootingByGroupName();
      }else{
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.finalDetails {
  .topBox {
    width: 100%;
    height: 22px;
    display: flex;
    .nameBox {
      height: 22px;
      margin-right: 80px;
    }
    .typeBox {
      height: 22px;
      margin-right: 80px;
    }
    .timeBox {
      height: 22px;
      margin-right: 70px;
    }
    .span1Box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
    }
    .span2Box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      .ydBox {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        margin-right: 5px;
      }
      .ydBox1 {
        background: #aaa;
      }
      .ydBox2 {
        background: #0e8ddf;
      }
      .ydBox3 {
        background: #00b050;
      }
      .ydBox4 {
        background: #00b050;
      }
      .nameBox {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .nameBox2 {
        color: #0e8ddf;
      }
      .nameBox3 {
        color: #00b050;
      }
      .nameBox4 {
        color: #00b050;
      }
    }
  }
  .groupBox11 {
    height: 30px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    div {
      cursor: pointer;
      width: 75px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      background: #fff;
      margin-right: 50px;
    }
    .active {
      width: 75px;
      height: 30px;
      background: #06206b;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .groupBox {
    height: 30px;
    display: flex;
    margin-top: 20px;
    div {
      cursor: pointer;
      width: 75px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      background: #fff;
      margin-right: 50px;
    }
    .active {
      width: 75px;
      height: 30px;
      background: #06206b;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .groupBox1 {
    color: #aaa;
  }
  .midBox {
    margin-top: 26px;
    .bwxxBox {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 20px;
    }
    .flBox {
      margin-top: 10px;
      height: 30px;
      line-height: 30px;
      display: flex;
      div {
        .ydBox {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          margin-right: 5px;
        }
        .ydBox0 {
          background: #aaa;
        }
        .ydBox1 {
          background: #00b050;
        }
        .ydBox2 {
          background: #f77120;
        }
        .ydBox3 {
          background: #0e8ddf;
        }
        .nameBox {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-right: 77px;
        }
        .nameBox0 {
          color: #aaa;
        }
        .nameBox1 {
          color: #00b050;
        }
        .nameBox2 {
          color: #f77120;
        }
        .nameBox3 {
          color: #0e8ddf;
          margin-right: 40px;
        }
      }
      button {
        cursor: pointer;
        width: 95px;
        height: 30px;
        line-height: 30px;
        background: #06206b;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #eaefff;
        padding: 0;
        border: none;
      }
      .timeBox {
        margin-left: 10px;
        .jsBox {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7d7d7d;
        }
        .tiBox {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e74a4a;
        }
      }
    }
    .playersBox {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      margin-top: 25px;
      .playBox {
        margin-right: 30px;
        margin-bottom: 20px;
        .playerBox {
          position: relative;
          cursor: pointer;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          background: #ffffff;
          box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.23);
          border-radius: 8px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 27px;
          }
        }
        .numberBox {
          width: 80px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 5px;
        }
        .LvOne {
          color: #00b050;
        }
        .LvTwo {
          color: #f77120;
        }
        .LvThree {
          color: #0e8ddf;
        }
        .LvOne1 {
          color: #00b050;
        }
        .LvTwo2 {
          color: #f77120;
        }
        .LvThree3 {
          color: #0e8ddf;
        }
      }
      .active {
        .LvOne {
          color: #fff;
          background-color: #00b050;
        }
        .LvTwo {
          color: #fff;
          background-color: #f77120;
        }
        .LvThree {
          color: #fff;
          background-color: #0e8ddf;
        }
      }
    }
    .playersBox1 {
      text-align: center;
      color: #aaa;
    }
  }
  .botBox {
    margin-top: 26px;
    .titleBox {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 20px;
      margin-bottom: 25px;
    }
    .tableBox {
      margin-top: 10px;
      // .el-table--border {
      // border-bottom: 1px solid #ebeef5;
      // border-right: 1px solid #ebeef5;
      // }
    }
  }
}
</style>

