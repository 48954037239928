<template>
  <div class="editRole">
    <!-- 新增成绩 -->
    <el-dialog
      :title="isEdit === 'add' ? '新增成绩' : '编辑成绩'"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="elForm"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item prop="ringValue">
          <span>环值</span>
          <br />
          <el-input
            v-model="formData.ringValue"
            placeholder="请输入环值"
          ></el-input>
        </el-form-item>
        <el-form-item prop="direction">
          <span>方向（1上、2下、3左、4右、5左上、6右下、7左下、8左上）</span>
          <br />
          <el-input
            v-model="formData.direction"
            placeholder="请输入方向"
          ></el-input>
        </el-form-item>
        <el-form-item prop="shootTime">
          <span>时间</span>
          <br />
          <el-time-picker
            v-model="formData.shootTime"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            :picker-options="{ selectableRange: '00:00:00-23:59:59' }"
            :style="{ width: '100%' }"
            placeholder="请选择时间"
            clearable
          ></el-time-picker>
        </el-form-item>
        <!--发数序号-->
        <el-form-item prop="no">
          <span>发数序号</span>
          <br />
          <el-input
            v-model="formData.no"
            placeholder="请输入发数序号"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="submitForm"
          size="small"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateShootByPlayer, addShootScore,updateLastScore } from '@/api/scoreQuery/index.js';
import moment from 'moment';
export default {
  data() {
    return {
      dialogVisible: false,
      isEdit: 'add',
      isEndChange:false,
      formData: {
        deviceName: '', // 设备标号
        createTime: "",
        direction: null, // 1-8，8个方向
        id: null,
        no:'', // 发数序号
        playerId: null, // 选手id
        ringValue: null, // 环数
        shootTime: moment(Date.now()).format('HH:mm:ss'), // 这一发的射击时间
      },
      loading: false,
      rules: {
        ringValue: [{
          required: true,
          message: '请输入环值',
          trigger: 'blur'
        }],
        direction: [{
          required: true,
          message: '请输入方向',
          trigger: 'blur'
        }],
        no: [{
          required: true,
          message: '请输入发数序号',
          trigger: 'blur'
        }],
        shootTime: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
      },
    };
  },
  methods: {
    // 打开弹层
    openEdit(edit, val) {
      this.isEdit = edit;
      this.isEndChange = (edit==='changeEndScore')?true:false;
      if (edit === 'add') {
        this.formData = {
          deviceName: val.deviceName,
          direction: val.direction,
          playerId: val.playerId,
          ringValue: val.ringValue,
          shootTime: (val.shootTime)?val.shootTime:moment(Date.now()).format('HH:mm:ss'),
        };
        this.dialogVisible = true;
      } else  {
        this.formData = {
          direction: val.direction,
          id: val.id,
          ringValue: val.ringValue,
          shootTime: val.shootTime,
          scheduleId: val.scheduleId,
          playerId:val.playerId,
          no:val.shootCount
        }
      };
      this.dialogVisible = true;
    },
    // 点击提交
    submitForm() {
      // console.log(this.formData);
      this.$refs['elForm'].validate(async (valid) => {
        if (valid) {
          this.formData.ringValue = Number(this.formData.ringValue);
          this.formData.direction = parseInt(this.formData.direction);
          if (this.isEdit === 'add') {
            this.loading = true;
            const res = await addShootScore(this.formData);
            if (res.status === 200) {
              this.$message({
                showClose: true,
                message: '新增成功！',
                type: 'success'
              });
              this.loading = false;
              this.$emit('getSelectShootingScore');
              this.dialogVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: '新增失败！' + res.msg,
                type: 'error'
              });
              this.loading = false;
            }
          } else if (this.isEdit === 'edit') {
            this.loading = true;
            const res = await updateShootByPlayer(this.formData);
            if (res.status === 200) {
              this.$message({
                showClose: true,
                message: '编辑成功！',
                type: 'success'
              });
              this.loading = false;
              this.$emit('getSelectShootingScore');
              this.dialogVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: '编辑失败！' + res.msg,
                type: 'error'
              });
              this.loading = false;
            }
          } else if (this.isEdit === 'changeEndScore') {
            this.loading = true;
            this.formData.ringValue = Number(this.formData.ringValue);
            this.formData.competitionId =Number(sessionStorage.getItem('competitionId')); 
            const res = await updateLastScore([this.formData]);
            if (res.status === 200) {
              this.loading = false;
              this.$message({
                showClose: true,
                message: '编辑成功！',
                type: 'success'

                });
                this.$emit('getSelectShootingScore');
              }else{
                this.loading = false;
                this.$message({
                  showClose: true,
                  message: '编辑失败！' + res.msg,
                  type: 'error'
                });
               
              }
        } else {
          console.log('error submit!!');
          this.loading = false;
          return false;
        }
      }
      });
    }
  }
}

</script>

<style lang="scss" scoped>
.editRole {
  ::v-deep.el-button--primary {
    margin-left: 30px;
  }
}
</style>