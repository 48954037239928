// 请求地址
// const baseUrl = 'http://192.168.0.115:8666/api'
// const baseUrl = 'http://shooting.zhihanyixing.com:8666/api' 
const baseUrl = 'https://shooting.zhihanyixing.com/api' 


// 大屏URL
 // const largeScreenUrl = 'http://localhost:8080'
// const largeScreenUrl = 'http://shooting.zhihanyixing.com:8666'
const largeScreenUrl = 'https://shooting.zhihanyixing.com'


// websocket地址
// const websocketUrl = 'ws://192.168.0.115:8666/api'
// const websocketUrl = 'ws://shooting.zhihanyixing.com:8666/api'
const websocketUrl = 'wss://shooting.zhihanyixing.com/api'



export default  {
  baseUrl: baseUrl,
  largeScreenUrl: largeScreenUrl,
  websocketUrl: websocketUrl
};